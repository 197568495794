<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Accessibility Standards</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              The State of Ohio is committed to delivering digital solutions
              that all people can access, including those with disabilities such
              as blindness and low vision, perceptual impairments, deafness and
              hearing loss, learning disabilities, and cognitive limitations.
            </p>
            <p>
              To achieve this goal, the Ohio Design System was developed to
              adhere to Web Content Accessibility Guidelines (WCAG) 2.0 Level A
              and Level AA compliance, as required by the state’s accessibility
              policy, IT-09.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <section id="user-centered">
          <div class="content-band">
            <p>
              Just as the Ohio Design System is organized around experience
              principles, accessibility guidelines are organized around the
              following four principles. From
              <a
                href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/intro.html#introduction-fourprincs-head"
                title="Understanding WCAG 2.0"
                target="_new"
                >Understanding WCAG 2.0</a
              >:
            </p>
            <ul class="bullet-red">
              <li>
                <span class="bold">Perceivable</span> - Information and user
                interface components must be presentable to users in ways they
                can perceive.
                <p>
                  This means that users must be able to perceive the information
                  being presented (it can't be invisible to all of their senses)
                </p>
              </li>

              <li>
                <span class="bold">Operable</span> - User interface components
                and navigation must be operable.
                <p>
                  This means that users must be able to operate the interface
                  (the interface cannot require interaction that a user cannot
                  perform)
                </p>
              </li>

              <li>
                <span class="bold">Understandable</span> - Information and the
                operation of user interface must be understandable.
                <p>
                  This means that users must be able to understand the
                  information as well as the operation of the user interface
                  (the content or operation cannot be beyond their
                  understanding)
                </p>
              </li>
              <li>
                <span class="bold">Robust</span> - Content must be robust enough
                that it can be interpreted reliably by a wide variety of user
                agents, including assistive technologies.
                <p>
                  This means that users must be able to access the content as
                  technologies advance (as technologies and user agents evolve,
                  the content should remain accessible)
                </p>
              </li>
            </ul>
            <p>
              If any of these are not true, users with disabilities will not be
              able to use State of Ohio digital products.
            </p>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band max-w1200">
            <h2 class="mb-20">Resources</h2>

            <div class="flex-grid gap-10">
              <a
                href="https://webaim.org/resources/contrastchecker/"
                target="_blank"
                class="card white fluid"
              >
                <div class="card__img external-link-icon icon red-700">
                  <img
                    src="../../assets/images/contrast-checker.png"
                    alt="Contrast Chekcer"
                  />
                </div>
                <div class="card__content">
                  <h4 class="semibold">WebAIM Contrast Checker</h4>
                  <p>
                    Contrast and Color Accessibility
                  </p>
                </div>
              </a>

              <a
                href="https://www.w3.org/WAI/WCAG21/quickref/"
                target="_blank"
                class="card white fluid"
              >
                <div class="card__img icon external-link-icon red-700">
                  <img
                    src="../../assets/images/wcag-quick-reference.png"
                    alt=""
                  />
                </div>
                <div class="card__content">
                  <h4 class="semibold">WCAG 2.0</h4>
                  <p>
                    Quick Reference
                  </p>
                </div>
              </a>

              <a
                href="https://www.w3.org/TR/WCAG20/"
                target="_blank"
                class="card white fluid"
              >
                <div class="card__img icon external-link-icon red-700">
                  <img src="../../assets/images/wcag-guidelines.png" alt="" />
                </div>
                <div class="card__content">
                  <h4 class="semibold">WCAG Guidelines</h4>
                  <p>
                    Contrast and Color Accessibility
                  </p>
                </div>
              </a>

              <a href="#" target="_blank" class="card white fluid">
                <div class="card__img icon external-link-icon red-700">
                  <img src="../../assets/images/wave-icon.png" alt="" />
                </div>
                <div class="card__content">
                  <h4 class="semibold">WAVE</h4>
                  <p>
                    Web Accessibility Evaluation Tool
                  </p>
                </div>
              </a>

              <a href="#" target="_blank" class="card white fluid">
                <div class="card__img external-link-icon icon red-700">
                  <img src="../../assets/images/axe-devTools-icon.png" alt="" />
                </div>
                <div class="card__content">
                  <h4 class="semibold">Axe DevTools</h4>
                  <p>
                    Browser Extensions
                  </p>
                </div>
              </a>

              <a href="#" target="_blank" class="card white fluid">
                <div class="card__img icon external-link-icon red-700">
                  <img src="../../assets/images/das-logo.png" alt="" />
                </div>
                <div class="card__content">
                  <h4 class="semibold">State of Ohio</h4>
                  <p>
                    Website Accessibility Policy, IT-09
                  </p>
                </div>
              </a>

              <a href="#" target="_blank" class="card white fluid">
                <div class="card__img icon external-link-icon red-700">
                  <img src="../../assets/images/nv-access-icon.png" alt="" />
                </div>
                <div class="card__content">
                  <h4 class="semibold">NV Access</h4>
                  <p>
                    Free screenreader for Windows
                  </p>
                </div>
              </a>

              <a
                href="#"
                target="_blank"
                class="card transparent fluid user-none"
              ></a>

              <a
                href="#"
                target="_blank"
                class="card transparent fluid user-none"
              ></a>
            </div>
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "AccessibilityStandards",
  components: {
    TopLevelLeftNav,
    SubFooter,
  },
};
</script>
